<script lang="ts">
  import { WebsiteName, WebsiteDescription } from "../../config"

  import LinkData from "$lib/components/centralci/LinkData.svelte"

  import HeroSection from "$lib/components/landing/HeroSection.svelte"
  import FeaturesSection from "$lib/components/landing/FeaturesSection.svelte"
  import CtaSection from "$lib/components/landing/CtaSection.svelte"
</script>

<svelte:head>
  <title>{WebsiteName} - {WebsiteDescription}</title>
  <meta name="description" content={WebsiteDescription} />
  <LinkData />
</svelte:head>

<div class="flex flex-col items-center grow self-stretch">
  <HeroSection />
  <FeaturesSection />
  <CtaSection />
</div>
