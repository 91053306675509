<script lang="ts">
  import Button from "$lib/components/ui/button/button.svelte"
  import { ArrowRightIcon } from "lucide-svelte"
</script>

<section
  id="cta"
  class="relative mx-auto mt-32 max-w-7xl px-6 text-center md:px-8"
>
  <h1
    class="-translate-y-4 animate-fade-in text-balance bg-gradient-to-br from-black from-30% to-black/40 bg-clip-text py-6 text-5xl font-medium leading-none tracking-tighter text-transparent opacity-0 [--animation-delay:200ms] dark:from-white dark:to-white/40 sm:text-6xl md:text-7xl lg:text-8xl"
  >
    See it in Action
  </h1>
  <p
    class="mb-12 -translate-y-4 animate-fade-in text-balance text-lg tracking-tight text-gray-400 opacity-0 [--animation-delay:400ms] md:text-xl"
  >
    CentralCI is deploying and scaling Concourse for you,
    <span class="underline decoration-secondary decoration-[3px]">
      to increase build rates and improve app quality faster.
    </span>
  </p>

  <Button
    href="https://centralci.com/account/settings"
    target="_blank"
    class="-translate-y-4 animate-fade-in gap-1 rounded-lg text-white opacity-0 ease-in-out [--animation-delay:600ms] dark:text-black"
  >
    <span>OK, run a cluster for me</span>
    <ArrowRightIcon
      class="ml-1 size-4 transition-transform duration-300 ease-in-out group-hover:translate-x-1"
    />
  </Button>
</section>
